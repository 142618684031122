.expense-filter {
  background-color: rgb(10, 37, 37);
  width: 100%;
}

.expense-filter input {
  background-color: rgb(81, 218, 149);
  color: white;
  border: 1px solid #1c2b31;
  border-radius: 5px;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  width: 220px;
}