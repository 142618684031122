.g-chart {
  width: 100%;
  height: 400px;
  background-color: rgb(10, 37, 37);
}

.g-chart .section-title {
  background-color: rgb(81, 218, 149);
  padding: 10px;
  font-weight: bold;
}