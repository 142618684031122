.add-expense {
  background-color: rgb(10, 37, 37);
  height: fit-content;
  height: 400px;
}

.add-expense .section-title {
  background-color: rgb(81, 218, 149);
  padding: 10px;
  font-weight: bold;
}

.add-expense form {
  display: block;
  padding: 10px;
}

.add-expense .input-block {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 10px;
}

.add-expense .input-block input, select {
  background-color: rgb(10, 37, 37);
  color: #ffffff;
  border: 1px solid #737e74;
  padding: 7px;
}

.add-expense form label {
  padding: 7px;
}

.add-expense .input-block input {
  width: 200px;
  height: 20px;
}

.add-expense .input-block input {
  background-color: rgb(10, 37, 37);
}

.add-expense .input-block select {
  width: 216px;
  height: 38px;
}

.add-expense form button {
  height: 35px;
  background-color: rgb(29, 165, 142);
  color: #ffffff;
  font-weight: bold;
  border-radius: 6px;
  width: 100px;
  float: right;
  margin: 10px;
}