.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.login form {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.login input {
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: rgb(29, 165, 142);
  color: white;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  width: 190px;
}

.login button {
  height: 35px;
  background-color: rgb(29, 165, 142);
  color: #ffffff;
  font-weight: bold;
  border-radius: 6px;
}