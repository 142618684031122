.expenseList {
  width: 100%;
  background-color: rgb(10, 37, 37);
}

.expenseList .section-title {
  background-color: rgb(81, 218, 149);
  padding: 10px;
  font-weight: bold;
}

.expenseList .scrollable {
  height: 270px;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.expenseList table {
  background-color: rgb(12, 97, 97);
  border-spacing: 0px;
  width: 100%;
}
.expenseList table th, td {
  padding: 10px;
  text-align: left;
}

.expenseList table th {
  background-color: rgb(10, 37, 37);
  color: aliceblue;
}
.expenseList table tr:nth-child(2n) {
  background-color: rgb(9, 75, 75);
}